/**
 * External dependencies.
 */
import classNames from "classnames"
import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"

/**
 * Internal dependencies.
 */
import "./styles/_index.scss"

const FilterButtons = ({ items, clickFilter, filter }) => {
  const [active, setActive] = useState(0)
  const [open, setOpen] = useState(false)
  const handleClick = index => {
    setActive(index)
    setOpen(value => !value)
  }

  return (
    <div className={classNames("filter-buttons", { "is-open": open })}>
      <div className="filter-buttons__trigger">
        <i className="icon icon--chevron-down-dark " />
      </div>
      <Row>
      <Col
            lg="auto"
            // key={index}
            className={classNames("filter-buttons__col", {
              "is-active": filter === "All",
            })}
          >
            <Button
              variant="outline-primary"
              className={classNames({ "is-active": filter === "All" })}
              onClick={() => {clickFilter("All");handleClick(0)}}
            >
              All Posts
            </Button>
          </Col>
        {items?.map((item, index) => (
          <Col
            lg="auto"
            key={index}
            className={classNames("filter-buttons__col", {
              "is-active": filter === item.Name,
            })}
          >
            <Button
              variant="outline-primary"
              className={classNames({ "is-active": filter === item.Name })}
              onClick={() => {clickFilter(item.Name);handleClick(index)}}
            >
              {item?.Name}
            </Button>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default FilterButtons
