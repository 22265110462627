/**
 * External dependencies
 */
import React from "react"
import { Container } from "react-bootstrap"

/**
 * Internal dependencies
 */
import FilterButtons from "../filter-buttons/filter-buttons"

import "./styles/_index.scss"

const NewsFilter = ({title, blogsCategory, clickFilter, filter}) => (
  <Container >
    <div className="news-filter">
    <h1>{title}</h1>
    <FilterButtons
    clickFilter={clickFilter}
    filter={filter}
      items={blogsCategory}
    />
    </div>
  </Container>
)

export default NewsFilter
