import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from "gatsby"
import TextField from "./Inputs/TextField";
import Recaptcha from "./Inputs/Recaptcha";
import { postFormData } from "./api/Api";
import { ScrollToTop, SendMail, FormValidation, gtmEvent } from "../components/common/utils";
import _ from "lodash";
import "./Form.scss";

const SubscribeForm = (props) => {
    const recaptchaRef = useRef();
    const initFormState = {
        formname: "Subscribe Form",
        name: '',
        email: ''
    }
    const validationFields = ['name', 'email'];
    const [formState, setFormState] = useState(initFormState);
    const [errors, setErrors] = useState({});
    const [errorFlag, setErrorFlag] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [btnProcess, setBtnProcess] = useState(false);

    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        var validation = FormValidation(formState, validationFields);
        if (validation.status) {
            setErrorFlag(false)
            setErrors(validation.errors)
            const token = await recaptchaRef.current.executeAsync();
            if (token) {
                setBtnProcess(true)
                var postData = _.cloneDeep(formState)
                postData['extra'] = formState;
                recaptchaRef.current.reset();
                postFormData(postData).then(async apiRes => {
                    setThankyou(true)
                    setFormState(initFormState)
                    setBtnProcess(false);
                    // scrollTop();
                    setTimeout(() => {
                        setThankyou(false)
                    }, 9000)
                    // GTM tracking event 
                    gtmEvent({ formType: 'Newsletter Subscription', formName: 'Newsletter Subscription' })
                    // lets send mail
                    postData['to_admin'] = "laura.wells@strakers.co.uk, marketing@strakers.co.uk";
                    postData['from_from'] = "marketing@strakers.co.uk";
                    postData['email_temp_user'] = 'newsletter_user';
                    postData['email_temp_admin'] = 'newsletter_admin';
                    postData['email_subject_user'] = 'Strakers - Newsletter';
                    postData['email_subject_admin'] = 'Strakers - Newsletter ';
                    await SendMail(postData);
                });
            }
        } else {
            setErrors(validation.errors)
            setErrorFlag(true)
            // scrollTop();
        }
    }

    const scrollTop = () => {
        ScrollToTop(document.getElementById('form-section'), 100)
    }
    const errClass = (field) => {
        if (errors[field]) {
            return 'field-error';
        }
        return ''
    }
    return (
        <React.Fragment>
            <form>
                                            {showthankyou && <div className="alert alert-success">
                                            Thank you for your interest. A member of our team will contact you shortly.
                                        </div>}
                                        {
                                            errorFlag &&
                                            <div className="alert alert-danger alert-error">Highlighted fields are required | invalid</div>
                                        }
<Row>
          <Col xs="12" md="6" lg="4" className="mb-1_6 pe-md-4 pe-lg-2">
                                            <TextField
                                                // label="Email Address"
                                                name={`name`}
                                                placeholder="Full Name"
                                                onChange={handleChange}
                                                value={formState[`name`]}
                                                className={errClass(`name`)}
                                            />
                                           </Col>
          <Col xs="12" md="6" lg="4" className="mb-1_6 ps-md-4 ps-lg-2">
          <TextField
                                                // label="Email Address"
                                                name={`email`}
                                                placeholder="Email Address"
                                                onChange={handleChange}
                                                value={formState[`email`]}
                                                className={errClass(`email`)}
                                            />
                                                      </Col>
          <Col xs="12" md="6" lg="4" className="pe-md-4">

                                        <Recaptcha recaptchaRef={recaptchaRef} />
                                        <button type="submit"
                                            disabled={btnProcess}
                                            onClick={(event) => { handleSubmit(event) }}
                                            className='btn btn--yellow body-m mb-1_6 mb-lg-0'
                                        >Submit</button>
                                             
          </Col>
        </Row>
      </form> 
      <p className="body-xs mb-0">
        Unsubscribe at any time. For more about how we use your information, see
        our <Link to="/privacy-policy/">Privacy Policy</Link>.
      </p>
        </React.Fragment >
    )

}
export default SubscribeForm;