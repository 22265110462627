/**
 * External dependencies.
 */
import React from "react"
import { Link } from "gatsby"
import { yupResolver } from "@hookform/resolvers/yup"
import { Col, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import * as yup from "yup"

/**
 * Internal dependencies.
 */
import "./styles/_index.scss"
import SubscribeFormBk from "../../Forms/SubscribeForm"
const schema = yup.object({
  "full-name": yup.string().required("Required"),
  email: yup.string().email("Email is not valid").required("Required"),
})



const SubscribeForm = () => {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: {
  //     "full-name": "",
  //     email: "",
  //   },
  // })

  // const onSubmit = data => console.log(data)

  // const handleError = name =>
  //   errors[name] && (
  //     <div className="error mt-2">
  //       <small className="text-danger">{errors[name].message}</small>
  //     </div>
  //   )

  return (
    <div className="subscribe-form">
      <h4>
      Subscribe to keep up to date with all things Strakers.
      </h4>
      {/* <h5>Nullam orci erat luctus nec sem eu suscipit.</h5> */}
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12" md="6" lg="4" className="mb-1_6 pe-md-4 pe-lg-2">
            <Form.Group>
              <Form.Control
                id="full-name"
                placeholder="Full Name"
                {...register("full-name")}
              />
              {handleError("full-name")}
            </Form.Group>
          </Col>
          <Col xs="12" md="6" lg="4" className="mb-1_6 ps-md-4 ps-lg-2">
            <Form.Group>
              <Form.Control
                type="email"
                id="email"
                {...register("email")}
                placeholder="Your Email"
              />
              {handleError("email")}
            </Form.Group>
          </Col>
          <Col xs="12" md="6" lg="4" className="pe-md-4">
            <button
              type="submit"
              className="btn btn--yellow body-m mb-1_6 mb-lg-0"
            >
              <strong>Subscribe</strong>
            </button>
          </Col>
        </Row>
      </form>
      <p className="body-xs mb-0">
        Unsubscribe at any time. For more about how we use your information, see
        our <Link to="/">Privacy Notice</Link>.
      </p> */}
      <SubscribeFormBk />
    </div>
  )
}

export default SubscribeForm
