const vlogs = [
  {
    image: "news1",
    video: "https://www.youtube.com/watch?v=ScMzIvxBSi4",
    title: "Interior Wellbeing Trends For 2022",
    date: "24th January 2022",
    href: "/",
  },
  {
    image: "news2",
    title: "Thinking of waiting until New Year to sell your property?",
    date: "18th January 2022",
    href: "/",
  },
  {
    image: "news3",
    video: "https://www.youtube.com/watch?v=ScMzIvxBSi4",
    title: "Adding Value To Your Property",
    date: "24th January 2022",
    href: "/",
  },
  {
    image: "news4",
    title: `Don't Miss Out On A Sale`,
    date: "10th January 2022",
    href: "/",
  },
]

export default vlogs
