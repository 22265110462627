/**
 * External dependencies
 */
import React from "react"
import { Container } from "react-bootstrap"

/**
 * Internal depedencies
 */
import SubscribeForm from "../../blocks/subscribe-form/subscribe-form"

const NewsSubscribe = ({
  addShell
  }) => (
  addShell
    ?
  <Container fluid="hg">
    <SubscribeForm />
  </Container>
  :
  <SubscribeForm />

)

export default NewsSubscribe
