/**
 * External dependencies
 */
import React, {useState} from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import Button from "../button/button"
import LinkIcon from "../link-icon/link-icon"
import "./styles/_index.scss"
import ContentBlock from "../content/Content";
const TermsCallout = (props,{ data = {} }) => {
  // console.log("text", props)
 return(
  <div className="property-callout ipad-view pt-32 pb-32">
    <div className="property-callout__head d-flex flex-column">
      {props?.Right_Sidebar_top &&
      <><h4 className="d-block text-center">{props?.Right_Sidebar_top?.Title}</h4>
      {props?.Right_Sidebar_top?.Cta_Buttons?.length > 0 &&
      <div className="property-callout__actions d-block">
        {props?.Right_Sidebar_top?.Cta_Buttons?.map((item, i) => {
            return(
        <Button link={item?.Link} href={item?.Url?.Alias} color={i === 0 ? "yellow" : "blue"}>
          {item?.Icon_Name &&
          <Button.Icon>
            <i className={`icon icon--${item?.Icon_Name}`}></i>
          </Button.Icon>
        }
          <Button.Text>{item?.Label}</Button.Text>
        </Button>
            )
          })}

        {/* <Button tel="01380723451">
          <Button.Icon>
            <i className="icon icon--phone"></i>
          </Button.Icon>

          <Button.Text>01380723451</Button.Text>
        </Button> */}
      </div>
}
      </>
}


      
    </div>
{props?.Right_Sidebar_Bottom &&
    <div className="property-callout__booking">
      <h3>{props?.Right_Sidebar_Bottom?.Title}</h3>
      <ContentBlock
              // title="Property Description"
              Content={props?.Right_Sidebar_Bottom?.Content}
            />
    {props?.Right_Sidebar_Bottom?.Cta_Btn &&

      <Button link={props?.Right_Sidebar_Bottom?.Cta_Btn?.Link} href={props?.Right_Sidebar_Bottom?.Cta_Btn?.Url?.Alias} color="outline">
        <Button.Text>{props?.Right_Sidebar_Bottom?.Cta_Btn?.Label}</Button.Text>
      </Button>
}
    </div>
}
  </div>
)
          }
export default TermsCallout
