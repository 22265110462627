import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import TeamDetailInfo from "../components/team-detail-info/team-detail-info"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import Layout from "../components/layout"
import HomeBanner from "../components/home-banner/home-banner"
import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useStaticQuery, graphql } from "gatsby"
import TeamBanner from "../components/home-banner/team-banner"
import OtherNewsSales from "../components/news-sales/other-news-sales"
import NewsBlockInfo from "../components/property-info/news-block-info"
import OtherNewsSlider from "../components/home-properties/other-news-slide"
import NewsDetailsActions from "../components/property-actions/news-details-actions"
import News from "../components/news/news"
const BlogDetail = (props) => {
  const newsdata = useStaticQuery(graphql`
  query {
    glstrapi {
      newsBanner(publicationState: LIVE) {
        News_Sidebar {
          Content
          Right_Sidebar_Bottom {
            Content
            Title
            Cta_Btn {
              Icon_Name
              Label
              Link
              Link_Type
              Url {
                Alias
              }
            }
          }
          Right_Sidebar_top {
            Title
            Cta_Buttons {
              Link_Type
              Icon_Name
              Label
              Link
              Url {
                Alias
              }
            }
          }
        }
      }
    }
  }
`)
  const GET_BLOG_DETAILS = gql`
  query GetBlog($URL: String!) {
    blogs(where: { URL: $URL }) {
      id
      imagetransforms
      Title
      URL
      Team_Member {
        id
          imagetransforms
          Name
          Email
          Designation
          Video_Url
          URL
          Image {
            alternativeText
            url
          }
      }
      Add_Content {
        Content
        Add_Image {
          Image {
            alternativeText
            url
          }
        }
      }
      Banner_Image{
        url
        alternativeText
      }
      Date
      Categories{
        Name
      }
      Video_Url
      Publish
     
    }
  }
`
  const { loading, error, data } = useQuery(GET_BLOG_DETAILS, {
    variables: { URL: props.slug },
    fetchPolicy: "no-cache"
  })
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }
  if (loading)
  return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </Container>
    </div>
  )

  return data?.blogs?.length > 0 ? (
    <Layout
    searchtype={"office"}
    propertyClass={"sticky-page-fix news-details-page"}
    >
      {data?.blogs?.map((Page, i) => {
        let processedImages = JSON.stringify({})
        if (Page?.imagetransforms?.Banner_Image_Transforms) {
          processedImages = Page.imagetransforms.Banner_Image_Transforms
        }
        const path = {
          pathname: `/${props?.alias}/${props?.slug}/`
        }
        return (
          <>
            <Seo
              title={
                Page ? Page?.Title : ""
              }
              location={path}
              description={
                Page
                  ? `Read about ${Page?.Title} here and subscribe to our newsletter to stay up-to-date about everything going on at Strakers.`
                  : ""
              }
              image={Page?.Banner_Image?.url ? Page.Banner_Image.url : null}
            />
            <Helmet
              bodyAttributes={{
                class: `templates-blog-details-template team-page`,
              }}
            />

            <Breadcrumbs Page={Page?.Title} alias={"latest-property-news"} type={"news-details-page"} />
           <NewsBlockInfo processedImages={processedImages} page="blog" newsdata={newsdata?.glstrapi?.newsBanner?.News_Sidebar} Pagename={Page?.Title}
           data={Page}
            />
            <NewsDetailsActions newsdata={newsdata?.glstrapi?.newsBanner?.News_Sidebar} />
            <OtherNewsSlider
            Title={"You might also be interested in..."}
            URL={Page?.URL}
            />
                    
          </>
        )
      })}
    </Layout>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
        {/* <Breadcrumbs
          className="news-breadcrumbs"
          Page={"News"} alias={"latest-property-news"}          
        /> */}
          <Route component={News} />
        </Switch>
      </Router>
    </Layout>
  )

}

export default BlogDetail

