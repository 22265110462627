/**
 * External dependencies
 */
 import { useStaticQuery, graphql } from "gatsby"

 const useNewsVlogs = () => {
   const { allFile: { nodes: items } } = useStaticQuery(graphql`
     query NewsVlog {
       allFile(filter: {relativeDirectory: {eq: "news-vlogs/images"}}) {
         nodes {
           relativePath
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
         }
       }
     }
   `)
 
   return items;
 }
 
 export default useNewsVlogs