/**
 * External dependencies
 */
import React, { useState, useEffect, useMemo } from "react"
import axios from "axios";

import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import { currencyFormat } from '@components/common/utils';
/**
 * Internal dependencies
 */
import useHomeProperties from "./use-home-properties"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import properties from "./static/properties"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import "./styles/_index.scss"
import Carousel from "../carousel/carousel"
import classNames from "classnames"
import gql from "graphql-tag"
import {
  isIOS,
  isMobile
} from "react-device-detect";
import { useQuery } from "@apollo/client"
import {
  FOR_SALE_PAGE_URL,
  TO_RENT_PAGE_URL,
  GUIDE_PAGE_URL,
  NEWS_PAGE_URL
} from "@components/common/site/constants"
import Modal from 'react-bootstrap/Modal';
import BookAViewingForm from "../../Forms/BookAViewing"
import CardVlog from "../news-vlogs/card-vlog";
const OtherNewsSlider = (props) => {
  const RELATED_NEWS = gql`
  query GetNewsRelated($URL: String!) {
    blogs(publicationState: LIVE
      where: {URL_ne: $URL, Publish: true}
      limit: 3) {
        id
          Categories {
            Name
          }
          imagetransforms
          Date
          Title
          URL
          Tail_Image {
            alternativeText
            url
          }
          Video_Url
    }
  }
`
const {
  loading: relatednewsloading,
  error: relatednewserror,
  data: relatednews,
} = useQuery(RELATED_NEWS, {
  variables: { URL: props.URL },
})
  // const data = useHomeProperties()
  // const images = useMemo(() => convertGatsbyImageNodes(data), [data])
  const propertiesCollection = useMemo(
    () =>
      relatednews?.blogs?.map((x, i) => {
        let processedImages = JSON.stringify({})
  if (x.imagetransforms?.Tail_Image_Transforms) {
    processedImages = x.imagetransforms.Tail_Image_Transforms
  }

        return (
          <Col
          key={x.title}
          className={classNames("news-vlogs__col", {
            // "d-none d-lg-block": index === 2,
            // "d-none d-xl-block": index === 3,
          })}
        >
          <CardVlog
            image={x.Tail_Image}
            video={x.Video_Url}
            title={x.Title}
            date={x.Date}
            href={NEWS_PAGE_URL.alias+'/'+x.URL+'/'}
            id={x.id}
            imagetransforms={processedImages}
            imgname={"blog.Tail_Image.list"}
            onImageHoverZoom
          />
        </Col>
        )
      }),
    [relatednews?.blogs]
  )

  return (
    relatednews?.blogs?.length > 0 &&
    <div className="home-properties area-guide pt-48 pb-48 pt-120 pb-120">
      <Container className="home-properties__inner">
        {/* <h6 className="home-properties__quote fw-bolder fz14 d-inline-block">
          {props.Cap_Title}
        </h6> */}

        <Row className="align-items-center align-items-lg-start justify-content-lg-between">
          <Col xs={12} lg="auto">
            <h3>{props.Title}</h3>
          </Col>

         
        </Row>
      </Container>
      {/* {JSON.stringify(propItems)} */}
      <div className="news-land">
      {relatednews?.blogs?.length > 0 &&
      <Carousel
        className="news-land__row flex-column flex-md-row"
        data={propertiesCollection}
        controllers={{ buttons: isMobile && propertiesCollection?.length > 1 ? true : !isMobile && propertiesCollection?.length > 3 ? true : false }}
        isFluidContainer={false}
      />}
      </div>
      
       
    </div>
  )
}

export default OtherNewsSlider
