/**
 * External dependencies
 */
import React, {useState, useEffect} from "react"
import NewsFilter from "../news-filter/news-filter"
import NewsVlogs from "../news-vlogs/news-vlogs"
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap"
import Button from "../button/button"
/**
 * Internal dependencies
 */
 import {
  NEWS_PAGE_URL
} from "@components/common/site/constants"
const News = (props) => {
    const data = useStaticQuery(graphql`
    query{
      glstrapi {
        blogCategories(publicationState: LIVE, where: {Publish: true}) {
            Name
          }
        blogs(sort: "Date:DESC", publicationState: LIVE, where: {Publish: true}) {
          id
          Categories {
            Name
          }
          imagetransforms
          Date
          Title
          URL
          Tail_Image {
            alternativeText
            url
          }
          Video_Url
        }
  
      }
    }
    `);

    const [blogs, setBlogs] = useState(data.glstrapi?.blogs)
    const [blogsCategory, setBlogsCategory] = useState(data.glstrapi?.blogCategories)
    const [filter, setFilter] = useState("All")
    const clickFilter = e => {
        setFilter(e)
        var arr = []
        data.glstrapi?.blogs
          .filter(list =>
            list.Categories?.map(c => c.Name).includes(e)
          )
          .map((item, index) => {
            arr.push(item)
          })
          setBlogs(e === "All" ? data.glstrapi?.blogs : arr)
      }


      const postsPerPage = 8
      let arrayForHoldingPosts = []
      // const [] = useState(0);
      const [postsToShow, setPostsToShow] = useState([])
      const [next, setNext] = useState(8)
      const loopWithSlice = (start, end) => {
        const slicedPosts = blogs?.slice(0, end)
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
        setPostsToShow(arrayForHoldingPosts)
      }
    
      const handleShowMorePosts = () => {
        loopWithSlice(
          postsToShow?.length > 20 ? next : 20,
          postsToShow?.length > 20 ? next + postsPerPage : 20 + postsPerPage
        )
        setNext(postsToShow?.length > 20 ? next + postsPerPage : 20 + postsPerPage)
      }
    
      useEffect(() => {
        loopWithSlice(0, 20)
        setNext(8)
      }, [filter])
    return (
      <div className="vlog-page bg-linear">
            <NewsFilter title={props?.Title}  clickFilter={clickFilter} filter={filter} blogsCategory={blogsCategory} />
            <NewsVlogs blogs={postsToShow} page={NEWS_PAGE_URL} />
            {postsToShow && blogs && postsToShow?.length !== blogs?.length && (
                <Container className="news-sales news-vlogs">
                <Button
                className="mb-3_2 mb-lg-6 btn--outline"
                color="tsp"
                borderColor="blue"
                textColor=""
                displayAsLink={false}
                onClick={handleShowMorePosts}
              >
                <Button.Text>Load more</Button.Text>
              </Button>
              </Container>
                )}
                
        </div>
    )
}

export default News
