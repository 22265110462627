/**
 * External dependencies
 */
import { Link } from "gatsby"
import React from "react"

/**
 * Internal dependencies
 */
import Card from "../card/card"
import moment from "moment"
const CardVlog = ({ onImageHoverZoom, image, video, title, date, href, imgname, id, imagetransforms }) => {
  const headProps = {
    onImageHoverZoom,
    image,
    video,
    alt: title+" - Strakers Estate Agents",
    imgname, id, imagetransforms, href
  };

  return (
    <Card className="card-vlog">
      {video ? (
        <Card.Head {...headProps} />
      ) : (
        <Link to={href}>
          <Card.Head {...headProps} />
        </Link>
      )}

      <Card.Body>
        <h5>
          <Link to={href}>{title}</Link>
        </h5>
        <p>{moment(date).format("Do MMMM YYYY")}</p>
      </Card.Body>
    </Card>
  )
}
export default CardVlog
