/**
 * External dependencies
 */
 import React, {useState, useEffect} from "react"
import { Container, Row, Col } from "react-bootstrap"
import HiddenText from "../hidden-text/hidden-text"
import LocRatingMap from '../map/loc-rating-map';
/**
 * Internal dependencies
 */
import PropertyCallout from "../property-callout/property-callout"
import PropertyIntro from "../property-intro/property-intro"
import PropertySchema from "../property-schema/property-schema"
import PropertyCalculator from "../property-calculator/property-calculator"

import Accordions from "../accordion/accordion"
import usePropertyInfo from "./use-property-info"
import "./styles/_index.scss"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import ContentBlock from "../content/Content";
import TermsCallout from "../property-callout/terms-callout";
import NewsSubscribe from "../news-subscribe/news-subscribe";
import VideoPlaceholder from "../video-placeholder/video-placeholder";
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import moment from "moment"
import { StarberryIcons } from "@components/icons/index";
const NewsBlockInfo = (props) => {
  // console.log("text", props)
  let processedImages = JSON.stringify({});
  if (props?.data?.Team_Member?.imagetransforms?.Image_Transforms) {
      processedImages = props?.data?.Team_Member?.imagetransforms.Image_Transforms;
  }

  const [scroll, setScroll] = useState(false)

  useEffect(() => {
      window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 350)
      })
  },[])

  return (
    <div className="property-info-wrapper news-blk">
      <Container className="property-info">
        <Row>
          <h1>{props.Pagename}</h1>
          <div className="d-md-flex  align-items-center grid-gap-15">
            {props?.data?.Team_Member?.Image?.url &&<div className="img-team">
            <ImageTransform imagesources={props?.data?.Team_Member?.Image?.url}
             renderer="srcSet"
              imagename="team.Image.small" attr={{ alt: props?.data?.Team_Member?.Name+" - Strakers Estate Agents", class:'' }} 
              imagetransformresult={processedImages}
               id={props?.data?.Team_Member?.id}/>
            </div>}
            <div className="name-team d-none d-md-block">
            {props?.data?.Team_Member?.Image?.url && <> by {props?.data?.Team_Member?.Name} - </>} {moment(props?.data?.Date).format("Do MMMM YYYY")} 
            </div>
            <div className="d-none d-md-block">/</div>
            <div className="category-team">
              <StarberryIcons iconName="tagIcon" className="bi" />
              {props?.data?.Categories?.map((list, index) => (
                            <>
                              {list.Name} 
                              {index === props?.data?.Categories?.length - 1
                                ? ""
                                : ", "}
                            </>
                          ))}
            </div>
          </div>
        </Row>
        <Row className="flex-column flex-lg-row justify-content-lg-between">

          <Col className="property-info__content  pt-32" lg="auto">
          <Col className="pb-32">
          {props?.data?.Banner_Image &&
          <VideoPlaceholder
          nowrapper={true}
          relative={true}
          image={props?.data?.Banner_Image}
          imagetransforms={props.processedImages}
          id={props?.data?.id}
          video={props?.data?.Video_Url}
          alt={props?.data?.Banner_Image?.alternativeText}
          // href={href}
          playBtnOnRight={false}
          imgname={props?.page === "blog" ? "blog.Banner_Image.list" : "video.Banner_Image.list"}
        />}
        </Col>
            {props?.data?.Add_Content?.map((item, i) => {
              return(
                <>
                <ContentBlock key={i}
                Content={item?.Content}
              />
              {item?.Add_Image?.length > 0 &&
              <Row className="img-bk">
                {item?.Add_Image?.map((list, j) => {
                  return(              <Col key={j} md={6}>

                  <img src={list.Image?.url} alt={list.Image?.alternativeText} />
  
                </Col>
  )
                })}

              </Row>
            }
              </>
              )
            })}
           
          <NewsSubscribe addShell={false}/>

          </Col>

          <Col className="property-info__callout" 
          // lg="auto"
          >
          <div className={`sticky-top ${scroll ? "scrolled" : ""}`}>
            <TermsCallout
              Right_Sidebar_Bottom={props?.newsdata?.Right_Sidebar_Bottom}
              Right_Sidebar_top={props?.newsdata?.Right_Sidebar_top}
            />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewsBlockInfo
