/**
 * External dependencies
 */
import React, {useState, useEffect} from "react"
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"

/**
 * Internal dependencies
 */
import vlogs from "./static/vlogs"
import CardVlog from "./card-vlog"
import useNewsVlogs from "./use-news-vlogs"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import CalloutBanner from "../../components/callout-banner/callout-banner"
import { useStaticQuery, graphql } from "gatsby"
import NewsSubscribe from "../news-subscribe/news-subscribe"
import {
  NEWS_PAGE_URL
} from "../../components/common/site/constants"
const NewsVlogs = ({blogs, page}) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      newsBanner(publicationState: LIVE) {
        Big_Title
        Content
        Label
        Subscribe_Content
        Subscribe_Title
        Title
        Link {
          Alias
        }
      }

    }
  }
`)
  // const data = useNewsVlogs()
  // const images = convertGatsbyImageNodes(data)
  const CardRender = ({ 
    image, video, title,
    date,
    href, id, imagetransforms,imgname, onImageHoverZoom }) => {
    return (
      <CardVlog
      image={image}
              video={video}
              title={title}
              date={date}
              href={href}
              id={id}
              imagetransforms={imagetransforms}
              imgname={imgname}
              onImageHoverZoom
        />
    )
  }
  return (
    <Container className="news-vlogs">
      <Row className="news-vlogs__row flex-column flex-md-row">
        {blogs?.map((x, index) => {
            let processedImages = JSON.stringify({})
            if (x.imagetransforms?.Tail_Image_Transforms) {
              processedImages = x.imagetransforms.Tail_Image_Transforms
            }
          return(
            <>
            {index % 8 === 0 && index !== 0 && index % 16 !== 0 && (
                    <CalloutBanner addShell={false} title={data?.glstrapi?.newsBanner?.Big_Title}
                    subTitle={data?.glstrapi?.newsBanner?.Title}
                    description={data?.glstrapi?.newsBanner?.Content}
                    linkHref={data?.glstrapi?.newsBanner?.Link}
                    linkText={data?.glstrapi?.newsBanner?.Label} />
                  )}
            {index % 16 === 0 && index !== 0 && (
              <div className="news-bk-pad-fix">
            <NewsSubscribe addShell={false}/>
            </div>
            )}
          <Col
            key={x.title}
            className={classNames("news-vlogs__col", {
              // "d-none d-lg-block": index === 2,
              // "d-none d-xl-block": index === 3,
            })}
          >
            <CardRender
              image={x.Tail_Image}
              video={x.Video_Url}
              title={x.Title}
              date={x.Date}
              href={page.alias+'/'+x.URL+'/'}
              id={x.id}
              imagetransforms={processedImages}
              imgname={"blog.Tail_Image.list"}
              onImageHoverZoom
            />
          </Col>
          </>
        )})}
      </Row>
    </Container>
  )
}

export default NewsVlogs
