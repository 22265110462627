/**
 * External dependencies
 */
import React, {useState} from "react"
import { Container, Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import Button from "../button/button"
import "./styles/_index.scss"
import BookAViewingForm from "../../Forms/BookAViewing"
import Modal from 'react-bootstrap/Modal';
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
const NewsDetailsActions = (props) =>  {
  return(

  <Container
    fluid="hg"
    className="property-actions d-lg-none position-sticky w-100 start-0 bottom-0 bg-grey"
  >
    <Row className="g-0">
    {props?.newsdata?.Right_Sidebar_top?.Cta_Buttons?.map((item, i) => {
      return(
        <Col>
        <Button link={item?.Link} href={item?.Url?.Alias} color={i === 0 ? "yellow" : "blue"}>
          {item?.Icon_Name &&
          <Button.Icon>
            <i className={`icon icon--${item?.Icon_Name}`}></i>
          </Button.Icon>
        }
          <Button.Text>{item?.Label}</Button.Text>
        </Button>
        </Col> )
          })}
      
    </Row>
    
  </Container>
)
  }
export default NewsDetailsActions
